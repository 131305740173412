import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateStory from './components/CreateStory';
import LinkStory from './components/LinkStory';
import ShortcutIntegration from './components/ShortcutIntegration';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<ShortcutIntegration />} />
            <Route
              path="/createStory"
              element={<CreateStory />}
            />
            <Route
              path="/linkStory"
              element={<LinkStory />}
            />
          </Routes>
        </Router>
    </div>
  );
}

export default App;