import React from 'react';
import zafClient from '../zafClient';

interface LinkButtonProps {
  to: string;
  handleLinkedStories: () => void;
}

const LinkButton: React.FC<LinkButtonProps> = ({ to, handleLinkedStories }) => {
  const openLinkStoryModal = () => {
    // Get the ticket ID from the current ticket
    zafClient.get('ticket').then(function (data: any) {
      const ticket = data.ticket;
      const ticketId = ticket.id;
  
      // Define the URL of your local "linkStory" page, including the ticket ID as a query parameter
      const linkStoryUrl = `${process.env.REACT_APP_WEBSITE_ENDPOINT}/linkStory?id=${ticketId}`;
  
      // Create a modal with the content from your local "linkStory" page
      zafClient.invoke('instances.create', {
        location: 'modal',
        url: linkStoryUrl,
        size: {
          width: '650px',
          height: '600px',
        },
      })
      .then(function (modalContext: any) {
        // The modal is on the screen now
        var modalClient = zafClient.instance(modalContext['instances.create'][0].instanceGuid);
        modalClient.on('modal.close', function () {
          console.log('Modal Closed');

          handleLinkedStories();
        });
      })
      .catch(function (error: any) {
        console.error('Error opening createStory modal:', error);
      });
    });
  };  

  return (
    <button
      onClick={openLinkStoryModal}
      className="transition ease-in-out hover:bg-hover-button-light-blue-zendesk hover:hover-link-zendesk text-sm py-1.5 px-4 ml-2 border border-link-blue-zendesk text-link-blue-zendesk rounded"
    >
      Link Existing Story
    </button>
  );
};

export default LinkButton;
