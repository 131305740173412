import React, { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';

import { useLocation } from 'react-router-dom';
import zafClient from '../zafClient';

interface Template {
  entity_type: string;
  id: string;
  name: string;
  story_contents: StoryContents;
}

interface Member {
  id: string;
  name: string;
}

interface StoryContents {
  description: string;
  story_type: string,
  group_id: Number,
  workflow_state_id: Number,
  project_id: Number,
  labels: string[],
  // labels: Label[],
  follower_ids: string[],
  owner_ids: string[],
  custom_fields: CustomField[],
}

interface CustomField {
  field_id: string;
  value_id: string;
}

interface Label {
  app_url: string;
  description: string;
  archived: boolean;
  entity_type: string;
  color: string;
  name: string;
  global_id: string;
  updated_at: string;
  external_id: null | string;
  id: number;
  created_at: string;
}

const CreateStory: React.FC = () => {
  const [name, setname] = useState < string > ('');
  const [selectedProject, setSelectedProject] = useState < string > ('');
  const [projects, setProjects] = useState < string[] > ([]);
  const [projectFields, setProjectFields] = useState < StoryContents | null > (null);
  const [projectFieldsErrors, setProjectFieldsErrors] = useState < { [key: string]: string } > ({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') ?? ''; // Use an empty string as a default if 'id' is null
  const [apiResponse, setApiResponse] = useState < Template[] > ([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState < Member[] > ([]);

  const [filteredFollowers, setFilteredFollowers] = useState < Member[] > ([]);
  const [isFollowersDropdownVisible, setIsFollowersDropdownVisible] = useState(false);
  const [filteredOwners, setFilteredOwners] = useState < Member[] > ([]);
  const [isOwnersDropdownVisible, setIsOwnersDropdownVisible] = useState(false);


  const [inputLabelsValue, setInputLabelsValue] = useState('');
  const [inputFollowersValue, setInputFollowersValue] = useState('');
  const [inputOwnersValue, setInputOwnersValue] = useState('');
  const blurTimeoutRef = useRef < NodeJS.Timeout | null > (null);

  useEffect(() => {
    if (id) {
      console.log(`Received ID from query parameters: ${id}`);
    }

    var requesterName = '',
      organizationName = '';

    // First, get the existing custom field value using a GET request
    const getOptions = {
      url: `/api/v2/tickets/${id}.json`, // Use the 'id' from the query parameters
      type: 'GET',
      contentType: 'application/json',
    };

    zafClient.request(getOptions)
      .then((res: any) => {
        console.log(res);

        // Get Requester and Organization ID
        let requesterId = res.ticket.requester_id,
          organizationId = res.ticket.organization_id;

        zafClient.request(getOptions)
          .then((res: any) => {
            console.log(res);

            // Get Requester Name using Requester ID
            const getRequesterOptions = {
              url: `/api/v2/users/${requesterId}.json`, // Use the 'id' from the query parameters
              type: 'GET',
              contentType: 'application/json',
            };

            zafClient.request(getRequesterOptions).then((res: any) => {
              console.log(res);
              requesterName = res.user.name;

              console.log(`requesterName: ${requesterName}`);
            });

            // Get Requester Name using Requester ID
            const getOrganizationOptions = {
              url: `/api/v2/organizations/${organizationId}.json`, // Use the 'id' from the query parameters
              type: 'GET',
              contentType: 'application/json',
            };

            zafClient.request(getOrganizationOptions).then((res: any) => {
              console.log(res);
              organizationName = res.organization.name;

              console.log(`organizationName: ${organizationName}`);

              const getShortcutOptions = {
                url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/entity-template/`,
                type: 'GET',
                contentType: 'application/json',
                headers: {
                  Authorization: "Bearer {{jwt.token}}",
                },
                jwt: {
                  algorithm: "HS256",
                  secret_key: "{{setting.apiToken}}",
                  expiry: 3600,
                },
                secure: true,
              };

              zafClient.request(getShortcutOptions)
                .then((response: any) => {
                  const apiResponse = response;
                  if (apiResponse) {
                    const filteredProjectIds = [
                      '642b1814-0848-4a15-ab8d-1cbf7902e2f7', // TrustedForm Support Escalation Bug Template
                      '642b185e-da77-47c5-a66b-a13480c6a102', // LeadConduit Support Escalation Bug Template
                      '642b19c3-1b5a-40f1-b7fe-19d360e09142', // Accounts Support Escalation Bug Template
                      '642c92b3-3cdf-4120-9852-fc737ea056cd', // Suppression List Support Escalation Bug Template
                    ];

                    const filteredTemplates = apiResponse.filter((template: Template) => {
                      return filteredProjectIds.includes(template.id);
                    });
                    
                    const projectNames = filteredTemplates.map((template: Template) => template.name);

                    apiResponse.forEach((item: any) => {
                      if (item.story_contents && item.story_contents.description) {
                        item.story_contents.description = item.story_contents.description.replace('Customers Affected:', `Customers Affected: ${requesterName}`).replace('Customer Affected Account Names:', `Customer Affected Account Names: ${organizationName}`);
                      }

                      // For labels field, convert the array of objects to an array of strings
                      if (item.story_contents && item.story_contents.labels) {
                        item.story_contents.labels = Object.values(item.story_contents.labels).map((label: unknown) => (label as Label).name);
                      }

                      console.log('item', item);
                    });

                    console.log('apiResponse', apiResponse);

                    setProjects(projectNames);
                    setApiResponse(apiResponse);
                  }
                });
            });
          });
      });

    // Get Members
    const getShortcutOptions = {
      url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/member/list/`,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: "Bearer {{jwt.token}}",
      },
      jwt: {
        algorithm: "HS256",
        secret_key: "{{setting.apiToken}}",
        expiry: 3600,
      },
      secure: true,
    };

    zafClient.request(getShortcutOptions)
      .then((response: any) => {
        const members = response;

        console.log('members', members);
        setMembers(members);
      });
  }, []);

  const findMemberById = (id: string) => {
    return members.find((member) => member.id === id);
  };

  const updateZendeskTicket = (zendeskId: string, shortcutId: string) => {
    // First, get the existing custom field value using a GET request
    const getOptions = {
      url: `/api/v2/tickets/${zendeskId}.json`, // Use the 'id' from the query parameters
      type: 'GET',
      contentType: 'application/json',
    };

    zafClient.request(getOptions)
      .then((res: any) => {
        console.log(res);
        const existingValue = res.ticket.custom_fields.find((field: any) => field.id === Number(process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID))?.value || '';

        // Split the existing value into an array of IDs
        const existingIds = existingValue ? existingValue.split(',') : [];

        // Check if the new ID already exists in the array
        if (!existingIds.includes(shortcutId)) {
          // If the new ID doesn't exist, add it to the array
          existingIds.push(shortcutId);
        }

        // Join the updated array of IDs back into a single string with "," as the separator
        const updatedValue = existingIds.join(',');

        // Update the custom field with the updated value using a PUT request
        const putOptions = {
          url: `/api/v2/tickets/${id}.json`,
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            ticket: {
              custom_fields: [
                { id: process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID, value: updatedValue },
              ],
            },
          }),
        };

        return zafClient.request(putOptions);
      })
      .then((res: any) => {
        console.log(res);
        // Successfully updated custom field
        var ticket = res.ticket;
        var customFieldData = ticket.custom_fields;
        console.log(customFieldData);
        setIsSubmitting(false);

        zafClient.invoke('destroy', {});
      })
      .catch((error: any) => {
        console.error('Error updating custom field:', error);
        setIsSubmitting(false);
      });
  };

  const handleSubmit = () => {

    // Initialize form field errors
    const formFieldErrors: { [key: string]: string } = {};

    // Check the title field
    if (!name) {
      formFieldErrors['name'] = 'Title is required';
    }
    if (!selectedProject) {
      formFieldErrors['selectedProject'] = 'Project is required';
    }

    console.log('projectFields', projectFields);

    if (projectFields) {
      const requiredFields: Array<keyof StoryContents> = ['description', 'story_type', 'group_id', 'workflow_state_id'];

      requiredFields.forEach((fieldName) => {
        // Use a type guard to refine the type of projectFields
        if (!(fieldName in projectFields) || !projectFields[fieldName]) {
          formFieldErrors[fieldName] = `${fieldLabelMapping[fieldName]} is required`;
        }
      });
    }

    console.log('formFieldErrors', formFieldErrors);

    // Set the form field errors in state
    setProjectFieldsErrors(formFieldErrors);

    // If there are field errors, prevent submission
    if (Object.keys(formFieldErrors).length > 0) {
      return;
    }

    setIsSubmitting(true);

    // Clear any previous field errors
    setProjectFieldsErrors({});

    setname('');

    console.log(`Selected Project: ${selectedProject}`);
    console.log('Form Fields:', projectFields);

    const selectedTemplate = apiResponse.find((template) => template.name === selectedProject);

    // Check if projectFields is not null before filtering properties
    const filteredProjectFields = {
      description: projectFields?.description ?? null,
      story_type: projectFields?.story_type ?? null,
      group_id: projectFields?.group_id ?? null,
      workflow_state_id: projectFields?.workflow_state_id ?? null,
      project_id: projectFields?.project_id ?? null,
      follower_ids: projectFields?.follower_ids ?? null,
      owner_ids: projectFields?.owner_ids ?? null,
      custom_fields: projectFields?.custom_fields ?? null,
    };

    // Assuming selectedTemplate.story_contents.labels is an object with Label properties
    const labels: Array<{ name: string }> | null = projectFields
      ? Object.values(projectFields.labels).map((label: string) => ({ name: label }))
      : null;

    // Prepare the data to send in the request body
    const requestData = {
      name: name,
      story_template_id: selectedTemplate ? selectedTemplate.id : null,
      requested_by_id: process.env.REACT_APP_REQUEST_BY_ID || '',
      external_links: [
        `${process.env.REACT_APP_ZENDESK_ENDPOINT}/agent/tickets/${id}`
      ],
      labels: labels,
      ...filteredProjectFields, // Include filteredProjectFields properties directly in requestData
    };

    // Define the options for the POST request
    const postOptions = {
      url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/story/`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(requestData),
      headers: {
        Authorization: "Bearer {{jwt.token}}",
      },
      jwt: {
        algorithm: "HS256",
        secret_key: "{{setting.apiToken}}",
        expiry: 3600,
      },
      secure: true,
    };

    // Send a POST request to create the story
    zafClient.request(postOptions)
      .then((res: any) => {

        console.log(res);
        // Extract the ID of the created story from the response
        const shortcutId = res.id; // Replace 'id' with the actual field name in the response

        console.log(`shortcutId: ${shortcutId}`);

        // Now, use this storyId in the subsequent GET request to update Zendesk ticket
        updateZendeskTicket(id, shortcutId);
      })
      .catch((error) => {
        console.error('Error creating story:', error);

        setIsSubmitting(false);
      });
  };


  const handleInputLabelsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputLabelsValue(e.target.value);
  };

  const handleInputKeyLabelsPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setProjectFields((prev) => {
        if (prev) {
          const newLabels = [...(prev.labels || []), inputLabelsValue.trim()] as string[];
          return {
            ...prev,
            labels: newLabels,
          };
        }
        return prev;
      });
      setInputLabelsValue('');
    }
  };

  const handleRemoveLabelsTag = (index: number) => {
    setProjectFields((prev) => {
      if (prev) {
        const newTags = [...(prev.labels || [])];
        newTags.splice(index, 1);
        return {
          ...prev,
          labels: newTags,
        };
      }
      return prev;
    });
  };

  const handleDropdownFollowersChange = (searchTerm: string) => {
    if (projectFields) {
      const filtered = members.filter((member) =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !projectFields.follower_ids?.some((followerId) => findMemberById(followerId)?.id === member.id)
      );

      setFilteredFollowers(filtered);
      setIsFollowersDropdownVisible(true);
    }
  };

  const handleInputFollowersChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputFollowersValue(e.target.value);
  };

  const handleAddFollowersTag = (follower: Member) => {
    setProjectFields((prev) => {
      if (prev) {
        const newFollowerIds = [...(prev.follower_ids || []), follower.id];
        return {
          ...prev,
          follower_ids: newFollowerIds,
        };
      }
      return prev;
    });

    setFilteredFollowers([]);
    setIsFollowersDropdownVisible(false);
    setInputFollowersValue('');
  };

  const handleRemoveFollowersTag = (index: number) => {
    setProjectFields((prev) => {
      if (prev) {
        const newFollowerIds = [...(prev.follower_ids || [])];
        newFollowerIds.splice(index, 1);
        return {
          ...prev,
          follower_ids: newFollowerIds,
        };
      }
      return prev;
    });
  };

  const handleInputFollowersBlur = () => {
    // Set a small delay to allow click events on the dropdown to trigger before closing
    blurTimeoutRef.current = setTimeout(() => {
      setIsFollowersDropdownVisible(false);
    }, 300);
  };

  const handleDropdownOwnersChange = (searchTerm: string) => {
    if (projectFields) {
      const filtered = members.filter((member) =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !projectFields.owner_ids?.some((ownerId) => findMemberById(ownerId)?.id === member.id)
      );

      setFilteredOwners(filtered);
      setIsOwnersDropdownVisible(true);
    }
  };

  const handleInputOwnersChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputOwnersValue(e.target.value);
  };

  const handleAddOwnersTag = (owner: Member) => {
    setProjectFields((prev) => {
      if (prev) {
        const newOwnersIds = [...(prev.owner_ids || []), owner.id];
        return {
          ...prev,
          owner_ids: newOwnersIds,
        };
      }
      return prev;
    });

    setFilteredOwners([]);
    setIsOwnersDropdownVisible(false);
    setInputOwnersValue('');
  };

  const handleRemoveOwnersTag = (index: number) => {
    setProjectFields((prev) => {
      if (prev) {
        const newOwnersIds = [...(prev.owner_ids || [])];
        newOwnersIds.splice(index, 1);
        return {
          ...prev,
          owner_ids: newOwnersIds,
        };
      }
      return prev;
    });
  };

  const handleInputOwnersBlur = () => {
    // Set a small delay to allow click events on the dropdown to trigger before closing
    blurTimeoutRef.current = setTimeout(() => {
      setIsOwnersDropdownVisible(false);
    }, 300);
  };



  const handleMouseEnter = () => {
    // Mouse entered the dropdown, cancel the timeout to prevent hiding
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    // Mouse left the dropdown, resume the timeout to allow hiding
    blurTimeoutRef.current = setTimeout(() => {
      setIsFollowersDropdownVisible(false);
    }, 300);
  };


  // useEffect(() => {
  //   // Focus on the input field when tags change
  //   const inputField = document.getElementById('labels');
  //   if (inputField) {
  //     inputField.focus();
  //   }
  // }, [tags]);


  // Define a mapping between field names and custom labels
  const fieldLabelMapping: { [key: string]: string } = {
    description: 'Description',
    story_type: 'Story Type',
    labels: 'Tags',
    follower_ids: 'Followers',
    owner_ids: 'Owners',
  };

  // Extract keys from projectFields (if it's an object, otherwise, set it to an empty array)
  const projectFieldKeys = projectFields ? Object.keys(projectFields) : [];

  const baseClassName = `transition ease-in-out text-white inline-flex items-center`;

  const buttonClassName = !isSubmitting
    ? `${baseClassName} rounded bg-button-blue-zendesk text-sm text-white hover:bg-hover-button-blue-zendesk py-1.5 px-6 border border-button-blue-zendesk`
    : `${baseClassName} cursor-progress rounded text-sm text-white bg-button-blue-zendesk py-1.5 px-6 border border-button-blue-zendesk`;


  return (
    <div className="container px-4 mx-auto text-xs text-black-zendesk">
      <h2 className="text-l font-semibold mb-4">Create New Story</h2>
      <div className="mb-4">
        <label htmlFor="name" className="block font-bold mb-2">
          Story Title
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => {
            setname(e.target.value);
            if (projectFieldsErrors['name'] && e.target.value.trim() !== '') {
              // Clear the error if there was one and a value is provided
              const updatedErrors = { ...projectFieldsErrors };
              delete updatedErrors['name'];
              setProjectFieldsErrors(updatedErrors);
            }
          }}
          className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors['name'] ? 'border-red-500' : 'border-field-grey-zendesk'
            }`}
        />
        {projectFieldsErrors['name'] && (
          <p className="text-red-500 text-xs mt-2">{projectFieldsErrors['name']}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="projectSelect" className="block text-black-zendesk font-bold mb-2">
          Template
        </label>
        <select
          id="projectSelect"
          name="projectSelect"
          value={selectedProject}
          onChange={(event) => {
            const selectedProject = event.target.value;
            setSelectedProject(selectedProject);

            console.log('selectedProject', selectedProject);

            if (selectedProject.trim() !== '' && projectFieldsErrors['selectedProject']) {
              // Clear the error if there was one and a value is provided
              const updatedErrors = { ...projectFieldsErrors };
              delete updatedErrors['selectedProject'];
              setProjectFieldsErrors(updatedErrors);
            }

            const selectedTemplate = apiResponse.find((template) => template.name === selectedProject);

            if (selectedTemplate) {
              setProjectFields(selectedTemplate.story_contents);
              setProjectFieldsErrors({}); // Clear any previous errors
            } else {
              setProjectFields(null);
              setProjectFieldsErrors({}); // Clear any previous errors
            }
          }}
          className={`form-select appearance-none pr-8 pl-2 bg-no-repeat w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors['selectedProject'] ? 'border-red-500' : 'border-field-grey-zendesk'
            }`}
        >
          <option value="">Select a Template</option>
          {projects.map((projectName) => (
            <option key={projectName} value={projectName}>
              {projectName}
            </option>
          ))}
        </select>
        {projectFieldsErrors['selectedProject'] && (
          <p className="text-red-500 text-xs mt-2">{projectFieldsErrors['selectedProject']}</p>
        )}
      </div>
      {
        selectedProject && projectFields && (
          <div>
            {projectFieldKeys
              .filter((fieldName) => fieldLabelMapping[fieldName] !== undefined)
              .map((fieldName) => (
                <div className="mb-4" key={fieldName}>
                  <label htmlFor={fieldName} className="block text-black-zendesk font-bold mb-2">
                    {fieldLabelMapping[fieldName]} {/* Use custom label */}
                  </label>

                  {fieldName === 'story_type' ? (
                    <div className="flex group flex-wrap items-stretch w-full mb-4 relative">
                      <div className="flex items-center">
                        <span className="flex items-center leading-normal bg-grey-lighter rounded group-focus-within:border-hover-zendesk rounded-r-none border border-r-0 border-field-grey-zendesk text-field-grey-zendesk pl-3 h-full whitespace-no-wrap text-sm">
                          {projectFields[fieldName] === 'bug'
                            ? <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-red-shortcut">
                              <svg
                                className="h-4 w-4 flex-no-shrink text-dark-red-shortcut"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -1 20 20"
                              >
                                <g clipPath="url(#a)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.856 4.403c-.24-.201-.275-.57-.054-.79l.49-.492a1 1 0 0 0-1.413-1.414l-1.065 1.064c-.246.247-.603.341-.948.291a6.051 6.051 0 0 0-1.732 0c-.345.05-.702-.044-.948-.29L7.12 1.707a1 1 0 1 0-1.414 1.414l.49.491c.222.222.187.59-.053.791A6.023 6.023 0 0 0 4.616 6.35c-.187.38-.558.651-.982.651H2a1 1 0 0 0 0 2h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 1 0 0 2h1.634c.424 0 .795.271.982.651a6.02 6.02 0 0 0 3.245 2.957c.578.22 1.139-.254 1.139-.873V15a1 1 0 1 1 2 0v2.735c0 .619.56 1.093 1.139.873a6.02 6.02 0 0 0 3.245-2.957c.187-.38.558-.651.982-.651H18a1 1 0 1 0 0-2h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1a1 1 0 1 0 0-2h-1.634c-.424 0-.794-.271-.982-.651a6.024 6.024 0 0 0-1.528-1.946Z" fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="a">
                                    <path fill="#fff" d="M0 0h20v20H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            : projectFields[fieldName] === 'feature'
                              ? <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-yellow-shortcut">
                                <svg
                                  className="h-4 w-4 flex-no-shrink text-dark-yellow-shortcut"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 -1 20 20"
                                >
                                  <g clipPath="url(#a)">
                                    <path
                                      d="M9.191 2.112a1 1 0 0 1 1.618 0l1.547 2.129a1 1 0 0 0 .829.411l2.564-.05a1 1 0 0 1 .993 1.229l-.648 2.76a1 1 0 0 0 .187.845l1.727 2.202a1 1 0 0 1-.35 1.517l-2.41 1.167a1 1 0 0 0-.546.706l-.536 2.709a1 1 0 0 1-1.45.689L10.47 17.23a1 1 0 0 0-.94 0l-2.245 1.195a1 1 0 0 1-1.451-.688l-.536-2.709a1 1 0 0 0-.545-.706l-2.41-1.167a1 1 0 0 1-.351-1.517l1.727-2.202a1 1 0 0 0 .187-.846l-.648-2.76a1 1 0 0 1 .993-1.228l2.564.05a1 1 0 0 0 .829-.411L9.19 2.112z"
                                      fill="currentColor"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="a">
                                      <path fill="#fff" d="M0 0h20v20H0z"></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              : projectFields[fieldName] === 'chore'
                                ? <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-blue-shortcut">
                                  <svg
                                    className="h-4 w-4 flex-no-shrink text-dark-blue-shortcut"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 -1 20 20"
                                  >
                                    <g clipPath="url(#a)">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.476 14.28c.6-.31 1.393-.108 1.644.52l.577 1.443a1.958 1.958 0 1 0 2.546-2.546l-1.444-.577c-.627-.251-.828-1.044-.519-1.644A6.5 6.5 0 0 0 6.7 2.253c-.343.098-.427.526-.172.776l1.897 1.86a2.5 2.5 0 1 1-3.536 3.536L3.03 6.528c-.25-.255-.678-.171-.777.172a6.5 6.5 0 0 0 9.223 7.58Z" fill="currentColor"></path>                                  </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                : ''}
                        </span>
                      </div>
                      <select
                        id={fieldName}
                        name={fieldName}
                        value={(projectFields && fieldName in projectFields) ? (projectFields as any)[fieldName] : ''}
                        className={`form-select appearance-none pr-8 pl-2 flex-shrink flex-grow flex-auto flex-1 py-2 border-l-0 border-r border group-focus-within:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'}`}
                        onChange={(e) => {
                          setProjectFields({
                            ...projectFields,
                            [fieldName]: e.target.value,
                          });
                          setProjectFieldsErrors({
                            ...projectFieldsErrors,
                            [fieldName]: '', // Clear any previous errors for this field
                          });
                        }}
                      >
                        <option value="bug">bug</option>
                        <option value="feature">feature</option>
                        <option value="chore">chore</option>
                      </select>
                    </div>
                  ) : fieldName === 'description' ? (
                    <textarea
                      id={fieldName}
                      name={fieldName}
                      value={(projectFields && 'description' in projectFields) ? projectFields.description : ''}
                      onChange={(e) => {
                        setProjectFields({
                          ...projectFields,
                          [fieldName]: e.target.value,
                        });
                        setProjectFieldsErrors({
                          ...projectFieldsErrors,
                          [fieldName]: '', // Clear any previous errors for this field
                        });
                      }}
                      style={{ height: '150px' }}
                      className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'}`}
                    />
                  ) : fieldName === 'labels' ? (
                    <div>
                      <input
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        value={inputLabelsValue}
                        onChange={handleInputLabelsChange}
                        onKeyPress={handleInputKeyLabelsPress}
                        className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'
                          }`}
                      />
                      <div className="flex flex-wrap">
                        {projectFields?.labels?.map((tag, index) => (
                          <div key={index} className="bg-blue-zendesk text-white px-2 py-1 rounded mr-2 mt-2 flex items-center">
                            <div>{tag}</div>
                            <button type="button" onClick={() => handleRemoveLabelsTag(index)} className="ml-2 font-bold focus:outline-none">
                              x
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : fieldName === 'follower_ids' ? (
                    <div className="relative">
                      <input
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        value={inputFollowersValue}
                        onChange={(e) => {
                          handleInputFollowersChange(e);
                          handleDropdownFollowersChange(e.target.value);
                        }}
                        onBlur={handleInputFollowersBlur}
                        // onKeyPress={handleInputKeyFollowersPress}
                        className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'
                          }`}
                      />
                      {inputFollowersValue && isFollowersDropdownVisible && (
                        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                          {filteredFollowers.map((member) => (
                            <div key={member.id} className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => handleAddFollowersTag(member)}>
                              {member.name}
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="flex flex-wrap mt-2">
                        {projectFields?.follower_ids?.map((followerId, index) => {
                          const member = findMemberById(followerId);
                          if (member) {
                            return (
                              <div key={index} className="bg-blue-zendesk text-white px-2 py-1 rounded mr-2 mt-2 flex items-center">
                                <div>{member.name}</div>
                                <button type="button" onClick={() => handleRemoveFollowersTag(index)} className="ml-2 font-bold focus:outline-none">
                                  x
                                </button>
                              </div>
                            );
                          }
                          return null; // Handle the case where the member is not found
                        })}
                      </div>
                    </div>
                    ) : fieldName === 'owner_ids' ? (
                      <div className="relative">
                        <input
                          type="text"
                          id={fieldName}
                          name={fieldName}
                          value={inputOwnersValue}
                          onChange={(e) => {
                            handleInputOwnersChange(e);
                            handleDropdownOwnersChange(e.target.value);
                          }}
                          onBlur={handleInputOwnersBlur}
                          // onKeyPress={handleInputKeyFollowersPress}
                          className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'
                            }`}
                        />
                        {inputOwnersValue && isOwnersDropdownVisible && (
                          <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                            {filteredOwners.map((member) => (
                              <div key={member.id} className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => handleAddOwnersTag(member)}>
                                {member.name}
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="flex flex-wrap mt-2">
                          {projectFields?.owner_ids?.map((ownerId, index) => {
                            const member = findMemberById(ownerId);
                            if (member) {
                              return (
                                <div key={index} className="bg-blue-zendesk text-white px-2 py-1 rounded mr-2 mt-2 flex items-center">
                                  <div>{member.name}</div>
                                  <button type="button" onClick={() => handleRemoveOwnersTag(index)} className="ml-2 font-bold focus:outline-none">
                                    x
                                  </button>
                                </div>
                              );
                            }
                            return null; // Handle the case where the member is not found
                          })}
                        </div>
                      </div>
                  ) : (
                    <input
                      type="text"
                      id={fieldName}
                      name={fieldName}
                      value={(projectFields && fieldName in projectFields) ? (projectFields as any)[fieldName] : ''}
                      onChange={(e) => {
                        setProjectFields({
                          ...projectFields,
                          [fieldName]: e.target.value,
                        });
                        setProjectFieldsErrors({
                          ...projectFieldsErrors,
                          [fieldName]: '', // Clear any previous errors for this field
                        });
                      }}
                      className={`w-full px-4 py-2 rounded border focus:border-hover-zendesk focus:outline-none ${projectFieldsErrors[fieldName] ? 'border-red-500' : 'border-field-grey-zendesk'}`}
                    />
                  )}

                  {projectFieldsErrors[fieldName] && (
                    <p className="text-red-500 text-xs mt-2">{projectFieldsErrors[fieldName]}</p>
                  )}
                </div>
              ))}
          </div>
        )
      }
      <div className="flex justify-end">

        <button
          disabled={isSubmitting}
          type="button"
          className={buttonClassName}
          onClick={handleSubmit}
        >
          {isSubmitting ? (
            <>
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Creating...
            </>
          ) : (
            'Create'
          )}
        </button>

      </div>
    </div>
  );
};

export default CreateStory;
