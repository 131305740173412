import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import zafClient from '../zafClient';

import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-fill.svg';
import { ReactComponent as NewWindowIcon } from '@zendeskgarden/svg-icons/src/12/new-window-fill.svg';
import { ReactComponent as LinkIcon } from '@zendeskgarden/svg-icons/src/16/link-fill.svg';

import { Tooltip } from '@zendeskgarden/react-tooltips'

interface StoryLink {
  id: number;
  object_id: number;
  type: string;
  object_workflow_state_id: number;
  subject_id: number;
  subject_workflow_state_id: number;
}

const LinkStory: React.FC = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState < any[] > ([]); // To store ID, name, and link
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);

  type LinkingStatus = Record<string | number, boolean>;
  const [linkingStatus, setLinkingStatus] = useState < LinkingStatus > ({});

  const [groups, setGroups] = useState < any[] > ([]);
  const [selectedGroup, setSelectedGroup] = useState < string > ('');
  const [zendeskGroup, setZendeskGroup] = useState < string > ('');

  const [workflows, setWorkflows] = useState < any[] > ([]);
  const [selectedWorkflowState, setSelectedWorkflowState] = useState < string > ('');

  const searchAndLinkStory = () => {
    setSearchResults([]);
    setSearchExecuted(true);

    const isId = !isNaN(Number(searchInput));

    if(isId) {
      setSelectedGroup('');
      setSelectedWorkflowState('');
    }

    const apiUrl = !isId
      ? `${process.env.REACT_APP_REST_API_ENDPOINT}/api/search-stories/?title=${searchInput}` + (selectedGroup ? ` AND team:${getGroupName(selectedGroup)}` : '') + (selectedWorkflowState ? ` AND is:${selectedWorkflowState}` : '')
      : `${process.env.REACT_APP_REST_API_ENDPOINT}/api/search-stories/?id=${searchInput}`;

    const getOptions = {
      url: apiUrl,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: "Bearer {{jwt.token}}",
      },
      jwt: {
        algorithm: "HS256",
        secret_key: "{{setting.apiToken}}",
        expiry: 3600,
      },
      secure: true,
    };

    setIsSubmitting(true);

    zafClient
      .request(getOptions)
      .then((response: any) => {
        // Extract matching stories from the response and format them
        const responseData = Array.isArray(response) ? response : [response];

        const matchingStories = responseData.map((story: any) => ({
          id: story.id,
          name: story.name,
          group_id: story.group_id,
          story_type: story.story_type,
          workflow_state_id: story.workflow_state_id,
          story_links: story.story_links,
          link: `${process.env.REACT_APP_SHORTCUT_ENDPOINT}/story/${story.id}`,
        }));

        setSearchResults(matchingStories);
        setIsSubmitting(false);
      })
      .catch((error: any) => {
        console.error('Error searching stories:', error);
        setIsSubmitting(false);
      });
  };

  const handleLink = (storyId: string) => {
    const requestData = {
      link: `${process.env.REACT_APP_ZENDESK_ENDPOINT}/agent/tickets/${id}`
    };

    // Add the story link to the story in Shortcut
    const putOptions = {
      url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/story/link/${storyId}/`,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(requestData),
      headers: {
        Authorization: "Bearer {{jwt.token}}",
      },
      jwt: {
        algorithm: "HS256",
        secret_key: "{{setting.apiToken}}",
        expiry: 3600,
      },
      secure: true,
    };

    setLinkingStatus(prevStatus => ({
      ...prevStatus,
      [storyId]: true,
    }));

    zafClient.request(putOptions)
      .then((res: any) => {

        // First, get the existing custom field value using a GET request
        const getOptions = {
          url: `/api/v2/tickets/${id}.json`, // Use the 'id' from the query parameters
          type: 'GET',
          contentType: 'application/json',
        };

        zafClient.request(getOptions)
          .then((res: any) => {
            console.log(res);
            const existingValue = res.ticket.custom_fields.find((field: any) => field.id === Number(process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID))?.value || '';

            // Split the existing value into an array of IDs
            const existingIds = existingValue ? existingValue.split(',') : [];

            // Check if the new ID already exists in the array
            if (!existingIds.includes(storyId)) {
              // If the new ID doesn't exist, add it to the array
              existingIds.push(storyId);
            }

            // Join the updated array of IDs back into a single string with "," as the separator
            const updatedValue = existingIds.join(',');

            // Update the custom field with the updated value using a PUT request
            const putOptions = {
              url: `/api/v2/tickets/${id}.json`,
              type: 'PUT',
              contentType: 'application/json',
              data: JSON.stringify({
                ticket: {
                  custom_fields: [
                    { id: process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID, value: updatedValue },
                  ],
                },
              }),
            };

            return zafClient.request(putOptions);
          })
          .then((res: any) => {
            console.log(res);
            // Successfully updated custom field
            var ticket = res.ticket;
            var customFieldData = ticket.custom_fields;
            console.log(customFieldData);
            setLinkingStatus(prevStatus => ({
              ...prevStatus,
              [storyId]: false,
            }));
            zafClient.invoke('destroy', {});
          })
          .catch((error: any) => {
            console.error('Error updating custom field:', error);
            setLinkingStatus(prevStatus => ({
              ...prevStatus,
              [storyId]: false,
            }));
          });
      })
  };

  const handleOpen = (result: any) => {
    // Open the story link using window.open
    window.open(result.link, '_blank');
  };

  useEffect(() => {
    if (!workflows.length) {
      const fetchWorkflows = async () => {
        const getWorkflowOptions = {
          url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/workflows/`,
          type: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: "Bearer {{jwt.token}}",
          },
          jwt: {
            algorithm: 'HS256',
            secret_key: "{{setting.apiToken}}",
            expiry: 3600,
          },
          secure: true,
        };

        try {
          const response: any[] = await zafClient.request(getWorkflowOptions); // Assuming response is an array
          setWorkflows(response);
        } catch (error) {
          console.error('Error fetching workflows:', error);
        }
      };

      fetchWorkflows();
    }
  }, [workflows]);

  useEffect(() => {
    if (!groups.length) {
      const fetchGroups = async () => {
        const getGroupOptions = {
          url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/groups/`,
          type: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: "Bearer {{jwt.token}}",
          },
          jwt: {
            algorithm: 'HS256',
            secret_key: "{{setting.apiToken}}",
            expiry: 3600,
          },
          secure: true,
        };

        try {
          const response: any[] = await zafClient.request(getGroupOptions); // Assuming response is an array
          setGroups(response);
        } catch (error) {
          console.error('Error fetching groups:', error);
        }
      };

      fetchGroups();
    }
  }, [groups]);

  useEffect(() => {
    const lineOfBusinessMapping: { [key: string]: string } = {
      'accounts': '61532c5b-f1a2-4ce9-b549-552110ac20db',
      'leadconduit': '5e8606d3-9476-4ad9-a25b-fc29de504634',
      'trustedform': '5e860698-d622-49a9-ae4b-217b12a73dbf',
      'leadsbridge': '645e8879-0b14-412d-a9a2-f82c31df5b63',
      'other': '',
    };

    if (!zendeskGroup) {
      const getLineOfBusiness = async () => {
        const getOptions = {
          url: `/api/v2/tickets/${id}.json`, // Use the 'id' from the query parameters
          type: 'GET',
          contentType: 'application/json',
        };

        zafClient
          .request(getOptions)
          .then(async (res: any) => {
            console.log('getLineOfBusiness executed');

            const lineOfBusiness = (
              res.ticket.custom_fields.find((field: { id: number; value: string | null }) => field.id === Number(process.env.REACT_APP_LINE_OF_BUSINESS_FIELD_ID))
                ?.value || ''
            );

            console.log('lineOfBusiness', lineOfBusiness);

            setZendeskGroup(lineOfBusiness);

            // Set the selected group based on the line of business
            if (lineOfBusiness && lineOfBusinessMapping.hasOwnProperty(lineOfBusiness)) {
              setSelectedGroup(lineOfBusinessMapping[lineOfBusiness]);
            }
          });
      };

      getLineOfBusiness();
    }
  }, [id, zendeskGroup]);

  const getGroupName = (groupId: string) => {
    return groups.find((group: any) => group.id === groupId)?.name;
  }

  const getWorkflowStateName = (workflowStateId: Number) => {
    // Find the workflow state object that matches the workflow_state_id
    const matchingWorkflowState = workflows.find(workflow => {
      return workflow.states.some((state: any) => state.id === workflowStateId);
    });

    return matchingWorkflowState?.states.find((state: any) => state.id === workflowStateId)?.name;
  }

  const getWorkflowStateType = (workflowStateId: Number) => {
    // Find the workflow state object that matches the workflow_state_id
    const matchingWorkflowState = workflows.find(workflow => {
      return workflow.states.some((state: any) => state.id === workflowStateId);
    });

    return matchingWorkflowState?.states.find((state: any) => state.id === workflowStateId)?.type;
  }

  const baseClassName = `transition ease-in-out text-white text-center inline-flex items-center py-2 px-6`;

  const buttonClassName = !isSubmitting
    ? `${baseClassName} rounded-r bg-button-blue-zendesk text-sm text-white hover:bg-hover-button-blue-zendesk border border-button-blue-zendesk`
    : `${baseClassName} cursor-progress rounded-r text-sm text-white bg-button-blue-zendesk border border-button-blue-zendesk`;

  return (
    <div className="container text-xs mx-auto px-4 text-black-zendesk">
      <h2 className="text-l font-semibold mb-4">Link Existing Story</h2>
      <div className="mb-4">
        <div className="flex items-center">
          <div className="flex group flex-wrap items-stretch w-full mb-4 relative">
            <div className="flex items-center">
              <span className="flex items-center leading-normal bg-grey-lighter rounded group-focus-within:border-hover-zendesk rounded-r-none border border-r-0 border-field-grey-zendesk text-field-grey-zendesk pl-3 h-full whitespace-no-wrap text-sm">
                <SearchIcon className="w-4 h-4" />
              </span>
            </div>
            <input
              type="text"
              id="searchInput"
              name="searchInput"
              className="flex-shrink flex-grow flex-auto flex-1 pl-3 py-2 border-l-0 border group-focus-within:border-hover-zendesk focus:outline-none border-field-grey-zendesk"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value.trim())}
              placeholder="Enter Title or ID to link"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                  searchAndLinkStory();
                }
              }}
            />
            <button
              disabled={isSubmitting}
              type="button"
              className={`${buttonClassName} w-24 h-full`} // Adjust the width as needed
              onClick={searchAndLinkStory}
            >
              {isSubmitting ? (
                <div
                  className="mx-auto h-full">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </svg>
                </div>

              ) : (
                <div className="mx-auto">
                  Search
                </div>
              )}
            </button>
          </div>
        </div>
        <div className='mb-4 flex'>
          <div className="mr-4 w-40">
            <label htmlFor="group" className="block mb-1 text-xs font-medium text-gray-700">Team</label>
            <select
              id="group"
              name="group"
              className={`form-select w-full appearance-none pr-8 pl-2 bg-no-repeat px-4 py-1 rounded border focus:border-hover-zendesk ${selectedGroup ? "border-hover-zendesk" : "border-field-grey-zendesk"
                } focus:outline-none`}
              value={selectedGroup}
              onChange={(e) => {
                console.log("Selected Group:", e.target.value);
                setSelectedGroup(e.target.value);
              }}
            >
              <option value="">- All -</option>
              {groups.map((group: any) => (
                <option key={group.id} value={group.id}>{group.name}</option>
              ))}
            </select>
          </div>
          <div className="w-28">
            <label htmlFor="workflowState" className="block mb-1 text-xs font-medium text-gray-700">State</label>
            <select
              id="workflowState"
              name="workflowState"
              className={`form-select w-full appearance-none pr-8 pl-2 bg-no-repeat px-4 py-1 rounded border focus:border-hover-zendesk ${selectedWorkflowState ? "border-hover-zendesk" : "border-field-grey-zendesk"
                } focus:outline-none`}
              value={selectedWorkflowState}
              onChange={(e) => {
                console.log("Selected Workflow State:", e.target.value);
                setSelectedWorkflowState(e.target.value);
              }}
            >
              <option value="">- All -</option>
              <option value="unstarted">Unstarted</option>
              <option value="started">Started</option>
              <option value="done">Done</option>
            </select>
          </div>
        </div>

        <h2 className="text-l font-semibold mb-4">Search Results</h2>

        {searchExecuted && !isSubmitting && searchResults.length === 0 && (
          <p className="mt-2 text-xs text-center">No stories found</p>
        )}

        {isSubmitting && (
          <p className="mt-2 text-xs text-center">Searching stories ...</p>
        )}
        {searchResults.length > 0 && (
          <div>
            <table className="w-full mx-auto text-xs">
              <thead className="border-b border-field-grey-zendesk mb-4">
                <tr>
                  <th className="py-2 pl-4 text-center">ID State</th>
                  <th className="py-2 pl-4 text-left">Story</th>
                  <th className="py-2 pl-4 text-left">Related Tickets</th>
                  <th className="py-2 p-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((story, index) => (
                  <tr key={index} className="mb-2 hover:bg-light-blue-zendesk border-b border-line-grey-zendesk transition ease-in-out">
                    <td className="py-2 text-left">
                      <div className="pt-2 group">
                        {story.id && story.workflow_state_id && (
                          <div className="pl-4 flex">
                            <span className=" text-black">
                              {story.id}
                            </span>
                              {(() => {
                                const workflowState = getWorkflowStateType(story.workflow_state_id);
                                const additionalClass = workflowState === 'unstarted' ? 'text-unstarted-shortcut' : workflowState === 'started' ? 'text-in-progress-shortcut' :
                                  workflowState === 'done' ? 'text-done-shortcut' : '';
                                return (
                                  <span className={`rounded capitalize inline-block px-1 ${additionalClass}`}>
                                    {workflowState}
                                  </span>
                                );
                              })()}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="py-2 pl-4 text-sm text-left ">
                      <div onClick={() => handleOpen(story)} className="pt-2 group">
                        <span className="break-words hover:underline hover:text-dark-link-zendesk cursor-pointer text-sm text-link-blue-zendesk">
                          {story.name}
                          <NewWindowIcon className="inline group-hover:opacity-100 opacity-0 text-link-blue-zendesk ml-2" />
                        </span>
                      </div>
                      <div className="py-2  text-xxs flex">
                        <>
                          {story.story_type === 'feature' && (
                            <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-yellow-shortcut">
                              <svg
                                className="h-4 w-4 inline-block flex-no-shrink text-dark-yellow-shortcut"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -1 20 20"
                              >
                                <g clipPath="url(#a)">
                                  <path
                                    d="M9.191 2.112a1 1 0 0 1 1.618 0l1.547 2.129a1 1 0 0 0 .829.411l2.564-.05a1 1 0 0 1 .993 1.229l-.648 2.76a1 1 0 0 0 .187.845l1.727 2.202a1 1 0 0 1-.35 1.517l-2.41 1.167a1 1 0 0 0-.546.706l-.536 2.709a1 1 0 0 1-1.45.689L10.47 17.23a1 1 0 0 0-.94 0l-2.245 1.195a1 1 0 0 1-1.451-.688l-.536-2.709a1 1 0 0 0-.545-.706l-2.41-1.167a1 1 0 0 1-.351-1.517l1.727-2.202a1 1 0 0 0 .187-.846l-.648-2.76a1 1 0 0 1 .993-1.228l2.564.05a1 1 0 0 0 .829-.411L9.19 2.112z"
                                    fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="a">
                                    <path fill="#fff" d="M0 0h20v20H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          )}
                          {story.story_type === 'bug' && (
                            <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-red-shortcut">
                              <svg
                                className="h-4 w-4 inline-block flex-no-shrink text-dark-red-shortcut"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -1 20 20"
                              >
                                <g clipPath="url(#a)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.856 4.403c-.24-.201-.275-.57-.054-.79l.49-.492a1 1 0 0 0-1.413-1.414l-1.065 1.064c-.246.247-.603.341-.948.291a6.051 6.051 0 0 0-1.732 0c-.345.05-.702-.044-.948-.29L7.12 1.707a1 1 0 1 0-1.414 1.414l.49.491c.222.222.187.59-.053.791A6.023 6.023 0 0 0 4.616 6.35c-.187.38-.558.651-.982.651H2a1 1 0 0 0 0 2h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 1 0 0 2h1.634c.424 0 .795.271.982.651a6.02 6.02 0 0 0 3.245 2.957c.578.22 1.139-.254 1.139-.873V15a1 1 0 1 1 2 0v2.735c0 .619.56 1.093 1.139.873a6.02 6.02 0 0 0 3.245-2.957c.187-.38.558-.651.982-.651H18a1 1 0 1 0 0-2h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1a1 1 0 1 0 0-2h-1.634c-.424 0-.794-.271-.982-.651a6.024 6.024 0 0 0-1.528-1.946Z" fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="a">
                                    <path fill="#fff" d="M0 0h20v20H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          )}
                          {story.story_type === 'chore' && (
                            <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-blue-shortcut">
                              <svg
                                className="h-4 w-4 inline-block flex-no-shrink text-dark-blue-shortcut"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 -1 20 20"
                              >
                                <g clipPath="url(#a)">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.476 14.28c.6-.31 1.393-.108 1.644.52l.577 1.443a1.958 1.958 0 1 0 2.546-2.546l-1.444-.577c-.627-.251-.828-1.044-.519-1.644A6.5 6.5 0 0 0 6.7 2.253c-.343.098-.427.526-.172.776l1.897 1.86a2.5 2.5 0 1 1-3.536 3.536L3.03 6.528c-.25-.255-.678-.171-.777.172a6.5 6.5 0 0 0 9.223 7.58Z" fill="currentColor"></path>                                  </g>
                                <defs>
                                  <clipPath id="a">
                                    <path fill="#fff" d="M0 0h20v20H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>)}
                        </>
                        {story.group_id && (
                          <span className="rounded inline-block font-bold bg-white border border-field-grey-zendesk px-1 py-1 ml-2">{getGroupName(story.group_id)}</span>
                        )}
                        {/* {story.workflow_state_id && (
                          <span className="rounded inline-block font-bold bg-white border border-field-grey-zendesk px-1 py-1 ml-2">{getWorkflowStateName(story.workflow_state_id)}</span>
                        )} */}
                      </div>
                    </td>
                    <td className="py-2 text-left">
                      <div className="pt-2 group">
                        {story.story_links && story.story_links.length > 0 && (
                          story.story_links
                          .filter((link: StoryLink) => link.type === "subject") // Filter links with type: "subject"
                          .map((link: StoryLink) => {
                            const workflowState = getWorkflowStateType(link.object_workflow_state_id);
                            const className = workflowState === 'unstarted' ? 'text-unstarted-shortcut' : workflowState === 'started' ? 'text-in-progress-shortcut' :
                              workflowState === 'done' ? 'text-done-shortcut' :
                                '';
                            return (
                              <div key={link.id} className="pl-4 flex">
                                <span className="text-black w-8">
                                  {link.object_id}
                                </span>
                                  {link.object_workflow_state_id && (
                                    <span className={`rounded capitalize inline-block px-1 ml-1 ${className}`}>
                                      {workflowState}
                                    </span>
                                  )}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </td>
                    <td className="py-2 pr-2 text-center">
                      <button
                        onClick={() => handleLink(story.id)}
                        className="transition ease-in-out bg-button-blue-zendesk text-white py-1 px-1 w-7 h-7 rounded hover:bg-hover-button-blue-zendesk"
                      >
                        {linkingStatus[story.id] ? (
                          <div
                            className="mx-auto h-full">
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline w-4 h-4 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <svg
                                aria-hidden="true"
                                role="status"
                                className="inline w-4 h-4 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </svg>
                          </div>

                        ) : (
                          <div className="">
                            <Tooltip content="Add Link">
                              <LinkIcon className="inline-block" />
                            </Tooltip>
                          </div>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        )}

    </div>
    </div >
  );
};

export default LinkStory;
