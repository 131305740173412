import React, { useState, useEffect } from 'react';

import CreateButton from './CreateButton';
import LinkButton from './LinkButton';
import zafClient from '../zafClient';

import { ReactComponent as LinkRemove } from '@zendeskgarden/svg-icons/src/12/link-remove-stroke.svg';
import { Tooltip } from '@zendeskgarden/react-tooltips'

const ShortcutIntegration: React.FC = () => {

  type StoryData = {
    id: string;
    name: string;
    type: string;
    link: string;
    workflow_state_name: string;
  };

  const [linkedStories, setLinkedStories] = useState < StoryData[] > ([]);
  const [loading, setLoading] = useState(false);

  type DeletingStatus = Record<string | number, boolean>;
  const [deletingStatus, setDeletingStatus] = useState < DeletingStatus > ({})

  type DeletedStatus = Record<string | number, boolean>;
  const [deletedStatus, setDeletedStatus] = useState < DeletedStatus > ({})

  // Function to update linked stories in ShortcutIntegration component
  const handleLinkedStories = () => {
    setLoading(true);

    // Reset the deletingStatus and deletedStatus
    setDeletingStatus({});
    setDeletedStatus({});

    // Get the ticket ID from the query parameters
    zafClient.get('ticket')
      .then(function (data: any) {
        const ticket = data.ticket;
        const ticketId = ticket.id;
        const getOptions = {
          url: `/api/v2/tickets/${ticketId}.json`, // Use the 'id' from the query parameters
          type: 'GET',
          contentType: 'application/json',
        };

        zafClient
          .request(getOptions)
          .then(async (res: any) => {
            console.log('{handleLinkedStories} executed');

            const idList = (
              res.ticket.custom_fields.find((field: { id: number; value: string | null }) => field.id === Number(process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID))
                ?.value || ''
            ).split(',');

            console.log('idList', idList);

            // Define an async function to fetch story data
            const fetchStoryData = async (storyId: string) => {
              try {
                const getStoryOptions = {
                  url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/story/${storyId}`,
                  type: 'GET',
                  contentType: 'application/json',
                  headers: {
                    Authorization: "Bearer {{jwt.token}}",
                  },
                  jwt: {
                    algorithm: "HS256",
                    secret_key: "{{setting.apiToken}}",
                    expiry: 3600,
                  },
                  secure: true,
                };

                const storyRes: { id: string, name: string, story_type: string, workflow_state_id: string } = await zafClient.request(getStoryOptions);

                const getWorkflowOptions = {
                  url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/workflows/`,
                  type: 'GET',
                  contentType: 'application/json',
                  headers: {
                    Authorization: "Bearer {{jwt.token}}",
                  },
                  jwt: {
                    algorithm: "HS256",
                    secret_key: "{{setting.apiToken}}",
                    expiry: 3600,
                  },
                  secure: true,
                };

                const workflows: any[] = await zafClient.request(getWorkflowOptions);

                // Find the workflow state object that matches the workflow_state_id
                const workflowStateId = storyRes.workflow_state_id;
                const matchingWorkflowState = workflows.find(workflow => {
                  return workflow.states.some((state: any) => state.id === workflowStateId);
                });

                const workflowStateName = matchingWorkflowState?.states.find((state: any) => state.id === workflowStateId)?.name;

                const storyData: StoryData = {
                  id: storyRes.id,
                  name: `${storyRes.name}`,
                  type: `${storyRes.story_type}`,
                  link: `${process.env.REACT_APP_SHORTCUT_ENDPOINT}/story/${storyRes.id}`,
                  workflow_state_name: workflowStateName || '',
                };

                return storyData;
              } catch (error) {
                console.error('Error getting story:', error);
                return `${ticketId} - Error fetching story data`;
              }
            };

            // Generate dummy data and update linkedStories
            const updatedStories = await Promise.all(
              idList
                .filter((id: any) => id.trim() !== '') // Filter out empty strings
                .map(async (id: any) => {
                  return await fetchStoryData(id);
                })
            );

            // Now, 'updatedStories' contains the generated or error data for each 'id'
            updatedStories.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));

            console.log(`updatedStories: ${updatedStories}`);

            setLinkedStories(updatedStories);
            setLoading(false);
          })
          .catch((error: any) => {
            console.error('Error getting ticket data:', error);
            setLoading(false);
          });
      })
      .catch((error: any) => {
        console.error('Error getting ticket data:', error);
        setLoading(false);
      });


  };

  const handleOpen = (link: string) => {
    // Open the story link using window.open
    window.open(link, '_blank');
  };

  const handleDelete = (storyId: string) => {
    setDeletingStatus(prevStatus => ({
      ...prevStatus,
      [storyId]: true,
    }));

    // Get the ticket ID from the query parameters
    zafClient.get('ticket')
      .then(function (data: any) {
        const ticket = data.ticket;
        const ticketId = ticket.id;

        const getOptions = {
          url: `/api/v2/tickets/${ticketId}.json`, // Use the 'id' from the query parameters
          type: 'GET',
          contentType: 'application/json',
        };

        return zafClient.request(getOptions).then(async (res: any) => {
          console.log('handleDelete executed');

          const requestData = {
            link: `${process.env.REACT_APP_ZENDESK_ENDPOINT}/agent/tickets/${ticketId}`
          };

          // Add the story link to the story in Shortcut
          const deleteOptions = {
            url: `${process.env.REACT_APP_REST_API_ENDPOINT}/api/story/link/${storyId}/`,
            type: 'DELETE',
            contentType: 'application/json',
            data: JSON.stringify(requestData),
            headers: {
              Authorization: "Bearer {{jwt.token}}",
            },
            jwt: {
              algorithm: "HS256",
              secret_key: "{{setting.apiToken}}",
              expiry: 3600,
            },
            secure: true,
          };

          zafClient.request(deleteOptions);
        }).then((res: any) => {

          // Execute Zendesk part of the delete
          const getOptions = {
            url: `/api/v2/tickets/${ticketId}.json`, // Use the 'id' from the query parameters
            type: 'GET',
            contentType: 'application/json',
          };

          zafClient
            .request(getOptions)
            .then(async (res: any) => {

              const existingValue = res.ticket.custom_fields.find((field: any) => field.id === process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID)?.value || '';

              // Split the existing value into an array of IDs
              let existingIds = existingValue ? existingValue.split(',') : [];

              existingIds = existingIds.filter((existingId: any) => existingId !== String(storyId));

              // Join the updated array of IDs back into a single string with "," as the separator
              const updatedValue = existingIds.join(',');


              // Update the custom field with the updated value using a PUT request
              const putOptions = {
                url: `/api/v2/tickets/${ticketId}.json`,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify({
                  ticket: {
                    custom_fields: [
                      { id: process.env.REACT_APP_SHORTCUT_IDS_FIELD_ID, value: updatedValue },
                    ],
                  },
                }),
              };

              return zafClient.request(putOptions);
            }).then((res: any) => {
              console.log('res', res);

              setDeletingStatus(prevStatus => ({
                ...prevStatus,
                [storyId]: false,
              }));
              setDeletedStatus(prevStatus => ({
                ...prevStatus,
                [storyId]: true,
              }));
            }).catch((error: any) => {
              console.error('Error updating story:', error);
              setDeletingStatus(prevStatus => ({
                ...prevStatus,
                [storyId]: false,
              }));
            });
        })
          .catch((error: any) => {
            console.error('Error deleting link in shortcut:', error);
            setDeletingStatus(prevStatus => ({
              ...prevStatus,
              [storyId]: false,
            }));
          });
      })
      .catch((error: any) => {
        console.error('Error getting ticket data:', error);
        setLoading(false);
      });



  };

  useEffect(() => {
    handleLinkedStories();
  }, []);

  return (
    <div className="container w-full mx-auto p-4 text-black-zendesk">
      <div className="">
        {/* Pass the {handleLinkedStories} function as a prop to CreateButton and LinkButton */}
        <CreateButton to="/createStory" handleLinkedStories={handleLinkedStories} />
        <LinkButton to="/linkStory" handleLinkedStories={handleLinkedStories} />
      </div>


      <h2 className="mt-12 mb-4 text-xs font-bold">Linked Stories</h2>
      {/* Show a loading message while the stories are being fetched else show table */}
      {loading ? (
        <div
          className="mb-12 text-center h-full">
          <svg
            aria-hidden="true"
            role="status"
            className="inline-block mt-6 w-6 h-6 text-blue-zendesk animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-6 h-6 text-blue-zendesk animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </svg>
        </div>

      ) : (
        <p className="mb-4">
          {linkedStories.length === 0
            ? <p className="text-xs text-center">No linked stories</p>
            :

            <div>
              <table className="w-full text-xs mx-auto">
                <thead className="border-b border-field-grey-zendesk mb-4">
                  <tr>
                    <th className="py-2 pl-4 text-left w-12">ID</th>
                    <th className="py-2 pl-4 text-left">Story</th>
                    <th className="py-2 pl-4 text-center w-12 pr-4">Actions</th>
                  </tr>
                </thead>
                <tbody className="">
                  {linkedStories.map((story, index) => (

                    <tr
                      key={index}
                      onClick={() => handleOpen(story.link)}
                      className={`transition duration-300 ease-in-out  border-line-grey-zendesk ${deletedStatus[story.id] ? 'border-0 mb-0 opacity-0 bg-red-200-zendesk' : 'mb-2 border-b hover:bg-light-blue-zendesk'} cursor-pointer
                        }`}
                    >
                      <td className={`${deletedStatus[story.id] ? 'p-0' : 'py-1 px-2 '} text-center w-8 max-h-full overflow-hidden transition-padding duration-700`}>
                        <div className={`${deletedStatus[story.id] ? 'max-h-0' : 'max-h-40'} overflow-hidden transition-max-height duration-700 `}>
                          <span className="">
                            {story.id}
                          </span>
                        </div>
                      </td>
                      <td className={`${deletedStatus[story.id] ? 'p-0' : 'py-1'} text-left overflow-hidden transition-padding duration-700`}>
                        <div
                          className={`${deletedStatus[story.id] ? 'max-h-0' : 'max-h-40'} overflow-hidden transition-max-height duration-700 `}
                        >
                          <div className="group mt-2">
                            <span className="break-words hover:underline hover:text-dark-link-zendesk cursor-pointer text-sm text-link-blue-zendesk">
                              {story.name}
                            </span>
                          </div>
                          <div className="py-2 text-xxs flex">
                            <>
                              {story.type === 'feature' && (
                                <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-yellow-shortcut">
                                  <svg
                                    className="h-4 w-4 flex-no-shrink text-dark-yellow-shortcut"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 -1 20 20"
                                  >
                                    <g clipPath="url(#a)">
                                      <path
                                        d="M9.191 2.112a1 1 0 0 1 1.618 0l1.547 2.129a1 1 0 0 0 .829.411l2.564-.05a1 1 0 0 1 .993 1.229l-.648 2.76a1 1 0 0 0 .187.845l1.727 2.202a1 1 0 0 1-.35 1.517l-2.41 1.167a1 1 0 0 0-.546.706l-.536 2.709a1 1 0 0 1-1.45.689L10.47 17.23a1 1 0 0 0-.94 0l-2.245 1.195a1 1 0 0 1-1.451-.688l-.536-2.709a1 1 0 0 0-.545-.706l-2.41-1.167a1 1 0 0 1-.351-1.517l1.727-2.202a1 1 0 0 0 .187-.846l-.648-2.76a1 1 0 0 1 .993-1.228l2.564.05a1 1 0 0 0 .829-.411L9.19 2.112z"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              )}
                              {story.type === 'bug' && (
                                <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-red-shortcut">
                                  <svg
                                    className="h-4 w-4 flex-no-shrink text-dark-red-shortcut"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 -1 20 20"
                                  >
                                    <g clipPath="url(#a)">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.856 4.403c-.24-.201-.275-.57-.054-.79l.49-.492a1 1 0 0 0-1.413-1.414l-1.065 1.064c-.246.247-.603.341-.948.291a6.051 6.051 0 0 0-1.732 0c-.345.05-.702-.044-.948-.29L7.12 1.707a1 1 0 1 0-1.414 1.414l.49.491c.222.222.187.59-.053.791A6.023 6.023 0 0 0 4.616 6.35c-.187.38-.558.651-.982.651H2a1 1 0 0 0 0 2h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 1 0 0 2h1.634c.424 0 .795.271.982.651a6.02 6.02 0 0 0 3.245 2.957c.578.22 1.139-.254 1.139-.873V15a1 1 0 1 1 2 0v2.735c0 .619.56 1.093 1.139.873a6.02 6.02 0 0 0 3.245-2.957c.187-.38.558-.651.982-.651H18a1 1 0 1 0 0-2h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1a1 1 0 1 0 0-2h-1.634c-.424 0-.794-.271-.982-.651a6.024 6.024 0 0 0-1.528-1.946Z" fill="currentColor"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              )}
                              {story.type === 'chore' && (
                                <span className="rounded inline-block scale-100 font-bold px-1 py-1 bg-light-blue-shortcut">
                                  <svg
                                    className="h-4 w-4 flex-no-shrink text-dark-blue-shortcut"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 -1 20 20"
                                  >
                                    <g clipPath="url(#a)">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.476 14.28c.6-.31 1.393-.108 1.644.52l.577 1.443a1.958 1.958 0 1 0 2.546-2.546l-1.444-.577c-.627-.251-.828-1.044-.519-1.644A6.5 6.5 0 0 0 6.7 2.253c-.343.098-.427.526-.172.776l1.897 1.86a2.5 2.5 0 1 1-3.536 3.536L3.03 6.528c-.25-.255-.678-.171-.777.172a6.5 6.5 0 0 0 9.223 7.58Z" fill="currentColor"></path>                                  </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>)}
                            </>
                            <span className="rounded inline-block font-bold bg-white  border border-field-grey-zendesk px-1 py-1 ml-2">{story.workflow_state_name}</span>

                          </div>
                        </div>

                      </td>
                      <td className={`${deletedStatus[story.id] ? 'p-0' : 'py-2 pr-2'} text-center w-12 transition-padding duration-300 overflow-hidden`}>
                        <div
                          className={`${deletedStatus[story.id] ? 'max-h-0' : 'max-h-full'} overflow-hidden transition-max-height duration-700 `}
                        >
                          <button
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDelete(story.id);
                            }}
                            className={`transition ease-in-out ${deletedStatus[story.id] ? 'hidden' : ''} bg-white border border-field-grey-zendesk py-1 px-1 w-7 h-7 rounded hover:border-red-300-zendesk hover:text-red-300-zendesk`}
                          >
                            {deletingStatus[story.id] ? (
                              <div
                                className="mx-auto h-full -z-1">
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 text-red-300-zendesk animate-spin"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 text-red-300-zendesk animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </svg>
                              </div>

                            ) : (
                              <div className="">
                                {
                                  <Tooltip content="Remove Link">
                                    <LinkRemove className="inline-block" />
                                  </Tooltip>
                                }
                              </div>
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </p>
      )}
    </div>
  );
};

export default ShortcutIntegration;