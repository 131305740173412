import React from 'react';
import zafClient from '../zafClient';

interface CreateButtonProps {
  to: string;
  handleLinkedStories: () => void;
}

const CreateButton: React.FC<CreateButtonProps> = ({ to, handleLinkedStories }) => {
  const openCreateStoryModal = () => {
    zafClient.get('ticket')
      .then(function (data: any) {
        let ticket = data.ticket;
        let id = ticket.id;
        console.log('id: ' + id);

        // Define the URL of your local "createStory" page
        const createStoryUrl = `${process.env.REACT_APP_WEBSITE_ENDPOINT}/createStory?id=${id}`;

        // Create a modal with the content from your local page
        zafClient.invoke('instances.create', {
          location: 'modal',
          url: createStoryUrl,
          size: {
            width: '450px',
            height: '600px',
          },
        })
          .then(function (modalContext: any) {
            // The modal is on the screen now
            var modalClient = zafClient.instance(modalContext['instances.create'][0].instanceGuid);
            modalClient.on('modal.close', function () {
              console.log('Modal Closed');

              handleLinkedStories();
            });
          })
          .catch(function (error: any) {
            console.error('Error opening createStory modal:', error);
          });
      })
      .catch(function (err: any) {
        console.log(err);
      });
  };

  return (
    <button
      onClick={openCreateStoryModal}
      className="transition ease-in-out rounded bg-button-blue-zendesk text-sm text-white hover:bg-hover-button-blue-zendesk py-1.5 px-6 border border-button-blue-zendesk"
    >
      Create Story
    </button>
  );
};

export default CreateButton;
