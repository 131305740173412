interface IMetadata<T> {
    appId: number;
    installationId: number;
    name: string;
    plan: {
      name: string;
    };
      settings?: T
  }
  
  interface IClient {
    invoke: (cmd: string, arg: any) => Promise<any>;
    get: (getter: string) => any;
    set: (setter: string, field: string) => any;
    metadata: <U>() => IMetadata<U>;
    request: <U>(data: Object) => Promise<U>;
    on: (eventName: string, listener: (...args: any) => any) => void;
    instance: (instanceGuid: string) => IClient; // You might need to adjust the return type
  }
  
  declare global {
      interface Window {
          ZAFClient: {
              init: () => IClient
          }
      }
  }
  
  let zafClient: IClient;
  if (typeof window.ZAFClient === "undefined") {
    // eslint-disable-line no-undef
    throw new Error("ZAFClient cannot run outside Zendesk");
  } else {
    zafClient = window.ZAFClient.init(); // eslint-disable-line no-undef
  }
  
  export default zafClient;